.custom-template {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: -325px;
  width: 325px;
  height: max-content;
  display: block;
  z-index: 1;
  background: #ffffff;
  transition: all 0.3s;
  z-index: 1003;
  box-shadow: -1px 1px 20px rgba(69, 65, 78, 0.15);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: all 0.5s;
}
body {
  font-family: "Montserrat", sans-serif !important;
  letter-spacing: 0.5px !important;
}
.custom-template.open {
  right: 0px;
}
.bar-list {
  border-left: 0px solid #000;
  border-bottom: 0px solid #000;
}
.bg-primary-gradient a:hover {
  color: #fff;
}
.custom-template .custom-toggle {
  position: absolute;
  width: 45px;
  height: 45px;
  background: rgb(88, 103, 221);
  top: 50%;
  left: -45px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 1px solid #177dff;
  cursor: pointer;
  color: #ffffff;
  box-shadow: -5px 5px 20px rgba(69, 65, 78, 0.21);
}

.custom-template .custom-toggle i {
  font-size: 20px;
  animation: 1.3s spin linear infinite;
}

.custom-template .title {
  padding: 15px;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border-top-left-radius: 5px;
  border-bottom: 1px solid #ebedf2;
  background: #5867dd;
}

.custom-template .custom-content {
  padding: 20px 15px;
  max-height: calc(100vh - 90px);
  overflow: auto;
}

.custom-template .switcher {
  padding: 5px 0;
}

.custom-template .switch-block h4 {
  font-size: 13px;
  font-weight: 600;
  color: #444;
  line-height: 1.3;
  margin-bottom: 0;
  text-transform: uppercase;
}

.custom-template .btnSwitch {
  margin-top: 20px;
  margin-bottom: 25px;
}

.custom-template .btnSwitch button {
  border: 0px;
  height: 20px;
  width: 20px;
  outline: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0;
  border-radius: 50%;
  border: 2px solid #eee;
  position: relative;
  transition: all 0.2s;
}

.custom-template .btnSwitch button:hover {
  border-color: #0bf;
}

.custom-template .btnSwitch button.selected {
  border-color: #0bf;
}

.custom-template .img-pick {
  padding: 4px;
  min-height: 100px;
  border-radius: 5px;
  cursor: pointer;
}

.custom-template .img-pick img {
  height: 100%;
  height: 100px;
  width: 100%;
  border-radius: 5px;
  border: 2px solid transparent;
}

.custom-template .img-pick:hover img,
.custom-template .img-pick.active img {
  border-color: #177dff;
}

.demo .btn,
.demo .progress {
  margin-bottom: 15px !important;
}

.demo .form-check-label,
.demo .form-radio-label {
  margin-right: 15px;
}

.demo .toggle,
.demo .btn-group {
  margin-right: 15px;
}

.demo #slider {
  margin-bottom: 15px;
}

.table-typo tbody > tr > td {
  border-color: #fafafa;
}

.table-typo tbody > tr > td:first-child {
  min-width: 200px;
  vertical-align: bottom;
}

.table-typo tbody > tr > td:first-child p {
  font-size: 14px;
  color: #333;
}

.demo-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  transition: all 0.2s;
}

.demo-icon:hover {
  background-color: #f4f5f8;
  border-radius: 3px;
}

.demo-icon .icon-preview {
  font-size: 1.8rem;
  margin-right: 10px;
  line-height: 1;
  color: #333439;
}

body[data-background-color="dark"] .demo-icon .icon-preview {
  color: #969696;
}

.demo-icon .icon-class {
  font-weight: 300;
  font-size: 13px;
  color: #777;
}

body[data-background-color="dark"] .demo-icon .icon-class {
  color: #a9a8a8;
}

.form-show-notify .form-control {
  margin-bottom: 15px;
}

.form-show-notify label {
  padding-top: 0.65rem;
}

.map-demo {
  height: 300px;
}

#instructions li {
  padding: 5px 0;
}

.row-demo-grid {
  margin-bottom: 15px;
}

.row-demo-grid [class^="col"] {
  text-align: center;
}

.row-demo-grid [class^="col"] .card-body {
  background: #ddd;
}

.btnSwitch button[data-color="white"] {
  background-color: #fff;
}
.btnSwitch button[data-color="grey"] {
  background-color: #f1f1f1;
}
.carousel-caption h5 {
  color: #fff !important;
}
.page-title,
.card-title {
  color: #002866 !important;
}
.btnSwitch button[data-color="black"] {
  background-color: #191919;
}
.btnSwitch button[data-color="dark"] {
  background-color: #1a2035;
}
.card-body h4,
.card-body h1 {
  color: #fff !important;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.ant-table-cell {
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.btnSwitch button[data-color="blue"] {
  background-color: #1572e8;
}
.btnSwitch button[data-color="purple"] {
  background-color: #6861ce;
}
.btnSwitch button[data-color="light-blue"] {
  background-color: #48abf7;
}
.btnSwitch button[data-color="green"] {
  background-color: #31ce36;
}
.btnSwitch button[data-color="orange"] {
  background-color: #ffad46;
}
.btnSwitch button[data-color="red"] {
  background-color: #f25961;
}
.btnSwitch button[data-color="dark2"] {
  background-color: #1f283e;
}
.btnSwitch button[data-color="blue2"] {
  background-color: #1269db;
}
.btnSwitch button[data-color="purple2"] {
  background-color: #5c55bf;
}
.btnSwitch button[data-color="light-blue2"] {
  background-color: #3697e1;
}
.btnSwitch button[data-color="green2"] {
  background-color: #2bb930;
}
.btnSwitch button[data-color="orange2"] {
  background-color: #ff9e27;
}
.btnSwitch button[data-color="red2"] {
  background-color: #ea4d56;
}
.btnSwitch button[data-color="bg1"] {
  background-color: #fafafa;
}
.btnSwitch button[data-color="bg2"] {
  background-color: #fff;
}
.btnSwitch button[data-color="bg3"] {
  background-color: #f1f1f1;
}

@media screen and (max-width: 550px) {
  .table-typo tr td {
    display: flex;
    align-items: center;
    word-break: break-word;
  }

  .table-typo tr td:first-child p {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 576px) {
  .custom-template .custom-content {
    overflow: auto;
  }
  .form-show-notify > .text-right,
  .form-show-validation > .text-right {
    text-align: left !important;
  }
}

@media screen and (max-width: 400px) {
  .custom-template {
    width: 85% !important;
    right: -85%;
  }
}

.accordion-button {
  background-color: transparent !important;
  border: 0;
  width: 100%;
  text-align: left;
  padding: 0;
}
.link-collapse {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  display: flex;
  flex-direction: column;
  padding: 7px 0;
}
.accordion-collapse {
  margin: 0 15px !important;
  padding: 0 15px !important;
}
.nav li a {
  text-decoration: none;
}
.nav-primary .nav-item {
  margin-bottom: 12px;
}
.slider--section {
  width: 35%;
}
.form--section {
  width: 65%;
  background-color: #fff;
}
.carousel-item img {
  height: 100vh;
  opacity: 0.4;
}
.carousel-caption h5 {
  font-size: 1.54rem;
  font-weight: 400;

  margin-bottom: 1.5rem;
}
.carousel-caption {
  position: absolute;
  left: 50%;
  top: 50%;
  right: 0;
  z-index: 10;
  transform: translate(-50%, -15%);
}
.carousel-caption p {
  font-size: 1rem;
  line-height: 1.54;
}
.overlay1 {
  background-image: linear-gradient(135deg, #667eea, #764ba2) !important;
}
.overlay2 {
  background-image: linear-gradient(160deg, #ccbb80, #f4967c) !important;
}
.overlay3 {
  background-image: linear-gradient(90deg, #434343 0, #000) !important;
}

.app-logo {
  margin-bottom: 3rem;
  height: 45px;
}
label {
  font-size: 0.88rem !important;
  font-weight: 400 !important;
  line-height: 1.5;
}
.app-login-box h2 {
  opacity: 0.6;
  margin-bottom: 0;
}
.app-login-box p {
  font-size: 1rem;
}
.rounded {
  border-radius: 35px !important;
}
.pointer,
.pointer:hover {
  cursor: pointer;
  text-decoration: none;
}
.btn-link:hover,
.btn-link {
  color: #002866;
  text-decoration: none;
}
.ant-checkbox-input,
.ant-checkbox-inner {
  display: none !important;
}

.ant-table-thead > tr > th {
  background: #03367d !important;
  color: #fff !important;
}
@media (max-width: 575px) {
  .form--section {
    width: 100%;
    height: 100vh;
  }
  .modal-dialog {
    max-width: 100%;
    margin: 1.75rem auto;
  }
}

.modal-dialog {
  max-width: 800px;
  margin: 1.75rem auto;
}


.updateProfile + .col-md-6, .updateProfile + .col-md-6 + .col-md-6{
  width: 51% !important;
  max-width: 100% !important;
  flex: none !important;
  margin:  0 auto;
}